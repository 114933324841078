.region {
    margin-top: 1rem;
    width: 100%;
    background-color: #fff;
}

.region_top {
    margin-top: 0;
    float: right;
    width: 59.5%;
}

.region_top_l {
    position: relative;
    overflow: hidden;
    /* margin-top: 2rem; */
    float: left;
    width: 39.5%;
    height: 382px;
    /* background-color: rgb(241, 241, 241); */
}

.region_top_l .title_top {
    position: absolute;
    width: 100%;
    top: 0;
    /* padding: 5px; */
    /* padding-right: 0; */
    /* color: #fff; */
    /* background-color: #06A4CB; */
}

.region_top_l .title_end {
    position: absolute;
    width: 100%;
    top: 45px;
    padding: 5px;
    /* padding-right: 2px; */
    color: #fff;
    background-color: #06A4CB;
}

.content-m .region_top_l {
    position: relative;
    overflow: hidden;
    /* margin-top: 2rem; */
    width: 100%;
    height: 100px;
    /* background-color: aliceblue; */
}

.content-m .region_top {
    width: 100%;
    margin-top: 2rem;
}


.region .region_nav {
    position: relative;
    width: 100%;
    height: 130px;
    color: #fff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.region_nav h2 {
    position: absolute;
    bottom: 0;
    left: 20px;
    font-size: 34px;
    font-weight: 500;
}

.region_nav b {
    font-size: 20px;
    font-weight: 500;
}

.region ul {
    padding-left: 0;
    margin: 0;
    width: 100%;
    /* overflow: hidden; */
}

.region ul .li {
    position: relative;
    list-style: none;
    width: 100%-6;
    height: 140px;
    border-left: 6px solid rgb(0, 136, 204);
    border-bottom: 1px solid #00000027;
    border-right: 1px solid #00000027;
}

.region_top ul .li {
    height: 120px;
}

.content-m .region_top ul .li {
    height: 100px;
}

.region ul .li .img {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 20px;
    left: 20px;
}

.region ul .li .h2 {
    position: absolute;
    top: 20px;
    left: 110px;
    color: black;
}

.region ul .li p {
    position: absolute;
    top: 45px;
    left: 110px;
    font-size: 14px;
    width: 32%;
    word-wrap: break-word;
    word-break: break-all;
}

.content-m .region ul .li p {
    width: 36%;
}

.region ul .li .tag {
    display: none;
    position: absolute;
    bottom: 8px;
    left: 110px;
}

.region ul .li .list {
    position: absolute;
    width: 500px;
    right: 0px;
}

.content-m .region ul .li .list {
    position: absolute;
    width: 200px;
    right: 0px;
    left: unset;
}

.region ul .li .topic {
    position: absolute;
    top: 20px;
    right: 500px;
    width: 100px;
    text-align: center;
}

.content-m .region ul .li .topic {
    position: absolute;
    top: 20px;
    left: unset;
    width: 100px;
    left: 140px;
    text-align: center;
}